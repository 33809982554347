import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    width: '100%',
    height: '100%',
  },
  image: {
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      // height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.4,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
    fontFamily: "'Noto Serif', serif",
    width: '100%',
  },
  focusVisible: {},
  imageMarked: {},
  imageButton: {
    margin: 'auto',
    color: '#f8f8f8',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.25,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    border: '.5px solid currentColor',
    borderRadius: '5px',
    backgroundColor: '#22222290',
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
}));

export const ParallaxButton = ({ title, href, goTo }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ButtonBase
        focusRipple
        key={title}
        className={classes.image}
        onClick={() => goTo(href)}>
        <span className={classes.imageBackdrop} />
        <span className={classes.imageButton}>
          <Typography variant='subtitle1' className={classes.imageTitle}>
            {title}
          </Typography>
        </span>
      </ButtonBase>
    </div>
  );
};
