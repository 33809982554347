import React, { useState } from 'react';
import Logo from '../images/logo-light-colors.png';
import { Nav, MobileMenu } from './Header-components/Nav';
import './Header-components/Header.css';
import Sticky from 'react-sticky-el';
import { useMediaQuery } from 'react-responsive';
import Hamburger from 'hamburger-react';

export const Header = (props) => {
  const [isMenuShown, updateMenuState] = useState(false);
  const { activeElement, goTo } = props;
  const isMobile = useMediaQuery({ maxWidth: 1224 });

  return isMobile ? (
    <Sticky stickyClassName='sticky-header'>
      <header>
        <div className='header-mobile'>
          <img alt='' src={Logo} width='50%' height='auto' />
          <Hamburger
            color='#f8f8f8'
            direction='right'
            duration={0.8}
            hideOutline={true}
            lable='Show menu'
            size={30}
            onToggle={() => updateMenuState(!isMenuShown)}
          />
        </div>
        <div>
          {isMenuShown ? (
            <div>
              <MobileMenu {...{ activeElement, goTo }} />
            </div>
          ) : null}{' '}
        </div>
      </header>
    </Sticky>
  ) : (
    <Sticky stickyClassName='sticky-header'>
      <header>
        <div className='header'>
          <img alt='' src={Logo} width='250px' height='auto' />
          <Nav {...{ activeElement, goTo }} />
        </div>
      </header>
    </Sticky>
  );
};
