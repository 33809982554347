import React from "react";
import "./BioElement-components/BioElement.css";

export const BioElement = (props) => {
  const {
    imageURL,
    name,
    title,
    description,
    lastLine,
    alignItems,
    padding,
    textAlign,
  } = props.bio;
  return (
    <div className="bio" style={{ alignItems: alignItems, padding: padding }}>
      <div style={{ alignSelf: "center" }}>
        <img alt="" src={imageURL} className="bio-image" />
        <div className="bio-name">{name}</div>
        <div className="bio-title">{title}</div>
      </div>
      <div className="bio-description" style={{ textAlign: textAlign }}>
        {description}
      </div>
      <div className="bio-last-line" style={{ textAlign: textAlign }}>
        {lastLine}
      </div>
    </div>
  );
};

// export const BioElement = (props) => {
//   const { refs } = props;
//   const { Bios } = props;
//   return (
// <div>
//     {
//      Bios.map(bio =>
//         const { Name } = props.bio;
//         const { Title } =props.bio;
//          <div>
//              <div className="name">{Name} </div>
//              <div className="title">{Title}</div>
//          </div>
//         )
//     }
// </div>

//   );
// };

//I think Bios.map goes somewhere in here.
