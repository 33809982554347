import React from "react";
import "./SectionHeader-components/SectionHeader.css";

export const SectionHeader = (props) => {
  const { SectionHeaderText, SectionHeaderWrap } = props;

  return (
    <div className="section-header-container">
      <span className="section-header-separator"></span>

      <span
        className="section-header-words"
        style={{ whiteSpace: SectionHeaderWrap }}
      >
        {SectionHeaderText}
      </span>
      <span className="section-header-separator"></span>
    </div>
  );
};
