import React from 'react';
import Button from '@material-ui/core/Button';
import './MyButton-components/MyButton.css';

export const MyButton = (props) => {
  const { activeElement, goTo } = props;
  const isActive = (elementName) => {
    return activeElement === elementName
      ? 'active-link navbar-link contact-button-inner'
      : 'navbar-link contact-button-inner';
  };
  return (
    <div className='contact-button-area'>
      <Button variant='contained' className='all-buttons contact-button'>
        <span
          className={isActive('GetStarted')}
          onClick={() => goTo('GetStarted')}>
          Let us find a solution for you. Get in touch today!
        </span>
      </Button>
    </div>
  );
};
