import React from 'react';
import Mountainscropped from '../images/mountain-stars-cropped.jpg';
import Greyscalelaptop from '../images/greyscale-laptop-sized.png';
import './Body-components/Body.css';
import { SectionOne, SectionTwo, ContactSection } from './Body-components';
import { useMediaQuery } from 'react-responsive';
import { ParallaxButton } from './ParallaxButton.js';

const Parallax = (props) => {
  const { url, parallaxPlace, title, href, goTo } = props;
  return (
    <div
      className={`${parallaxPlace} all-parallax`}
      style={{
        backgroundImage: `url(${url})`,
      }}>
      <ParallaxButton {...{ title, href, goTo }} />
    </div>
  );
};

export const Body = ({ refs, activeElement, goTo, ...props }) => {
  const isMobile = useMediaQuery({ query: '(max-device-width: 1224px)' });
  return (
    <div>
      <Parallax
        url={Mountainscropped}
        parallaxPlace='top-parallax'
        title='Do you want to reduce unnecessary steps in your day-to-day practices?'
        href='WhatWeDo'
        {...{ isMobile, goTo }}
      />
      <SectionOne {...{ refs, activeElement, goTo, isMobile }} />
      <Parallax
        url={Greyscalelaptop}
        parallaxPlace='middle-parallax'
        title='Contact us to get started helping your business'
        href='GetStarted'
        {...{ isMobile, goTo }}
      />
      <SectionTwo {...{ refs, isMobile }} />
      <ContactSection {...{ refs, isMobile }} />
    </div>
  );
};
