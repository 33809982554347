import React from 'react';
import './SectionTwo-components/SectionTwo.css';
import { SectionHeader } from './SectionHeader.js';
import { BioElement } from './BioElement.js';
import sadieFlour from '../../images/Blythe_noBG.png';
import sadieMusic from '../../images/Emma_noBG.png';
// import { useMediaQuery } from "react-responsive";

// //  className={isMobile ? "header-mobile" : "header"}

export const SectionTwo = (props) => {
  const { refs, isMobile } = props;
  const bios = [
    {
      imageURL: sadieMusic,
      name: 'Emma',
      title: 'Head Honcho and Web Developer',
      description:
        'After 5+ years of multi-platform coding, I have the experience needed to get the job done from start to finish.  I have helped supply solutions to companies ranging from start-ups to large corporations. Different needs require different solutions, and my flexible background allow me develop a solution within any environment.',
      lastLine:
        'Wherever I am, I’m probably working my way through today’s pot of tea and listening to classic rock.',
      alignItems: 'flex-start',
      padding: '0px 25px 0px 0px',
      textAlign: 'left',
    },
    {
      imageURL: sadieFlour,
      name: 'Blythe',
      title: 'Solutions Woman and Design Expert',
      description:
        'With my small business management background, I know how challenging finding the right solution for your business can be.  Past business owners I’ve worked with have looked to me to find or make them something that will fit the business’s needs as it grows. I have a passion for making things work and planning ahead!',
      lastLine:
        'When I’m not creating and coding, I enjoy hiking and baking bread.',
      alignItems: 'flex-end',
      padding: '0px 0px 0px 25px',
      textAlign: 'right',
    },
  ];

  return (
    <div className='sections' ref={refs.AboutUs}>
      <SectionHeader
        SectionHeaderWrap='nowrap'
        SectionHeaderText='About Us'
        RefPointer='AboutUs'
        {...{ refs }}
      />
      <div className='about-us-text'>
        Currently based out of Kansas City, KS, we are a married couple of
        professionals who focus on excellence in all things. We are notorious
        for being picky with words, loving the outdoors, and collecting entirely
        too many varieties of tea. With our trusty dog Sadie, we approach each
        day with humor and curiosity. Our relaxed attitude makes working with us
        an easy experience, as we keep the more technical aspects behind the
        scenes.
      </div>
      <div className='about-us-container'>
        {bios.map((bio, index) => (
          <BioElement key={index} {...{ bio }} />
        ))}
      </div>
      <div
        className={isMobile ? 'reviews-container-mobile' : 'reviews-container'}>
        <div className='review' style={{ backgroundColor: '#d6ba73' }}>
          Emma is amazing! She did a killer job despite all the challenges
          presented. Incredible patience and persistence. I highly recommend
          her.” — Dave
        </div>
        <div className='review' style={{ backgroundColor: '#b7b7b7' }}>
          “Great communicator, hard working, flexible, available, and always
          finds a solution.” — Tony
        </div>
        <div className='review' style={{ backgroundColor: '#bca9b6' }}>
          Emma was great. I especially appreciated her clear and quick
          communication. Highly recommended.” — Noam
        </div>
      </div>
      <div></div>
    </div>
  );
};
