import React from "react";
import "./Nav.css";

export const Nav = (props) => {
  const { activeElement, goTo } = props;
  const isActive = (elementName) => {
    return activeElement === elementName
      ? "active-link navbar-link"
      : "navbar-link";
  };
  return (
    <div className="navbar">
      <span className={isActive("WhatWeDo")} onClick={() => goTo("WhatWeDo")}>
        What We Do
      </span>
      <span className={isActive("AboutUs")} onClick={() => goTo("AboutUs")}>
        About Us
      </span>
      <span
        className={isActive("GetStarted")}
        onClick={() => goTo("GetStarted")}
      >
        Get Started
      </span>
    </div>
  );
};

export const MobileMenu = (props) => {
  const { activeElement, goTo } = props;
  return (
    <div className="mobile-menu">
      <Nav {...{ activeElement, goTo }} />
    </div>
  );
};
