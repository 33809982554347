import React from 'react';
import './App.css';
import { Header, Body, Footer } from './components';
import ScrollNavigation from 'react-single-page-navigation';
import { StylesProvider } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';

function App() {
  return (
    <div className='App'>
      <StylesProvider injectFirst>
        <ScrollNavigation
          elements={{
            WhatWeDo: {},
            AboutUs: {},
            GetStarted: {},
            Footer: {},
          }}
          offset={-120}>
          {({ refs, activeElement, goTo }) => (
            <React.Fragment>
              <Header {...{ useMediaQuery, activeElement, goTo }} />
              <Body {...{ refs, activeElement, goTo }} />
              <Footer {...{ refs }} />
            </React.Fragment>
          )}
        </ScrollNavigation>
      </StylesProvider>
    </div>
  );
}

export default App;
