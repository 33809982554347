import React, { useState } from 'react';
import { Button, Field, Form } from 'react-form-package';
import './ContactSection.css';
import { SectionHeader } from './SectionHeader.js';
import Stamp from '../../images/postage_stamp.png';
import Fade from '@material-ui/core/Fade';

export const ContactSection = (props) => {
  const { refs, isMobile } = props;
  const [sent, updateSent] = useState(false);

  const handleOnClick = async (state) => {
    updateSent(true);
    try {
      await fetch(
        'https://script.google.com/macros/s/AKfycbzDVOJ6hfC99WLsta_PbLAZ-JxQ2Gbp06OyR2D9_4YkRjDOGQQ/exec',
        {
          method: 'POST',
          body: JSON.stringify(state.data),
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className='sections' ref={refs.GetStarted}>
      <SectionHeader
        SectionHeaderWrap='nowrap'
        SectionHeaderText='Get Started'
        RefPointer='GetStarted'
        {...{ refs }}
      />
      <Fade in={!sent} unmountOnExit enter>
        <div className={isMobile ? 'form-container-mobile' : 'form-container'}>
          <Form>
            <div className='form-top'>
              <div className='form-top-fields'>
                <div className='input-div'>
                  <div className='question'>Business or Contact Name:</div>
                  <Field type='text' id='name' className='input-field' />
                </div>
                <div className='input-div'>
                  <div className='question'>E-Mail:</div>
                  <Field type='email' id='email' className='input-field' />
                </div>
              </div>
              {isMobile ? null : (
                <div className='form-top-stamp'>
                  <img src={Stamp} alt='stamp' className='stamp-image' />
                </div>
              )}
            </div>
            <div className='input-div'>
              <div className='question'>How may we help you?</div>
              <Field
                rows='8'
                cols='auto'
                type='textarea'
                id='question'
                className='text-field'
              />
            </div>
            <div>
              <Button
                id='submit'
                type='submit'
                onClick={handleOnClick}
                className='submit-button all-buttons'>
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Fade>
      <Fade in={sent} timeout={0.2}>
        <div>
          Thanks for messaging us. We'll get back to you as soon as we can.
        </div>
      </Fade>
    </div>
  );
};
