import React from 'react';
import './Footer-components/Footer.css';
import { Facebook } from '@material-ui/icons';

export const Footer = (props) => {
  const { refs } = props;
  return (
    <div className='footer' ref={refs.Footer}>
      <div className='footer-content'>
        <div className='credit-text'>
          <span>Copyright ursaCodes, LLC 2020</span>
          <br />
          <span>
            Cartoon credit: Toni{' '}
            <a className='twitter-link' href='https://twitter.com/teanewton'>
              @TeaNewton
            </a>
          </span>
        </div>
        <div className='footer-social-media'>
          <a
            className={`social-media-item facebook`}
            target='_blank'
            rel='noopener noreferrer'
            href={'https://www.facebook.com/ursaCodes'}>
            <Facebook />
          </a>
        </div>
      </div>
    </div>
  );
};
