import React from "react";
import Ccode from "../../images/C-code.png";
import Ccommunicate from "../../images/C-communicate.png";
import Ccomplete from "../../images/C-complete.png";
import Ccustomize from "../../images/C-customize.png";
import "./SectionOne-components/SectionOne.css";
import { SectionHeader } from "./SectionHeader.js";
import { MyButton } from "./MyButton.js";

export const SectionOne = (props) => {
  const { refs, activeElement, goTo } = props;
  return (
    <div className="sections" ref={refs.WhatWeDo}>
      <SectionHeader
        SectionHeaderWrap="wrap"
        SectionHeaderText="Do you want to reduce unecessary steps in your day-to-day"
        RefPointer="WhatWeDo"
        {...{ refs }}
      />
      <div className="info">
        Let us help you find solutions that improve efficiency, simplify
        business processes, and streamline workflows. By using familiar products
        combined with custom code, we will create the optimal solution for your
        needs. Whether you expect your business to grow or remain steady, we
        will shape our product to you.
      </div>

      <div className="four-cs-container">
        <div className="four-cs">
          <img alt="" src={Ccode} className="four-cs-image" />
          <br></br>
          <span>
            Let’s talk. To provide an excellent product that fits your business,
            we discuss your needs in depth and provide you with options that fit
            your business model.
          </span>
        </div>
        <div className="four-cs">
          <img alt="" src={Ccommunicate} className="four-cs-image" />
          <br></br>
          <span>
            Are you planning to grow your business? Do you need something simple
            or something more flexible? We fit our product to you.
          </span>
        </div>
        <div className="four-cs">
          <img alt="" src={Ccomplete} className="four-cs-image" />
          <br></br>
          <span>
            Our solutions stand for themselves. We create high quality,
            effective products that work to reduce your overhead and simplify
            your workflow.
          </span>
        </div>
        <div className="four-cs">
          <img alt="" src={Ccustomize} className="four-cs-image" />
          <br></br>
          <span>
            Our code is notable… because it doesn’t need your attention.
            Post-product support is rarely necessary because we provide products
            that don’t need much upkeep.
          </span>
        </div>
      </div>
      <MyButton {...{ refs, activeElement, goTo }} />
    </div>
  );
};
